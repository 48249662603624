import { useDetectConfirmationBanner } from '../../hooks/use-detect-confirmation-banner'
import { Flex, Stack, Text, TextProps } from '../../primitives'
import { BoxProps as Props } from '@chakra-ui/react'

interface HeaderProps extends Props {
  renderLeft?: React.ReactNode
  renderTitle: React.ReactNode
  renderRight?: React.ReactNode
  customStyle?: React.CSSProperties
}

export function Header({ renderTitle, renderLeft = null, renderRight = null, ...props }: HeaderProps) {
  const topOffset = useDetectConfirmationBanner()

  return (
    <Flex
      borderBottom={'1px solid'}
      borderColor="gray.50"
      position={['relative', 'relative', 'fixed']}
      top={topOffset ? "35px" : 0}
      px={['0', '20px', '20px']}
      pb = {['20px', '0px', '0px']}
      bg="white"
      h={['fit-content', '66px', '66px']}
      className="full-width-main"
      zIndex={10}
      alignItems={'center'}
      justifyContent="space-between"
      data-testid="main-content-header"
      {...props}
    >
      <Stack alignItems={'center'} shouldWrapChildren={true} isInline spacing={'20px'}>
        {renderLeft}
        {renderTitle}
      </Stack>
      <Flex justifyContent={'flex-end'} flex={1}>
        {renderRight}
      </Flex>
    </Flex>
  )
}

export const HeaderTitle = (props: TextProps) => (
  <Text display={['none', 'none', 'inherit']} color="indigo.100" fontSize="2xl" {...props} />
)
