import * as React from 'react'
import { useUserEmailStatus } from '../Features/UserSettings/use-user-settings'

export function useDetectConfirmationBanner():boolean {
    const [topOffset, setTopOffset] = React.useState<boolean>(false)
    const UserData = useUserEmailStatus()
    
    React.useEffect(() => {
      // when there is PHP email confirmation banner, check for it's presence and move left sidebar nav down 35px
      // to avoid overlapping elements
      const hasEmailConfirmationBanner = document.querySelectorAll('.confirm_email_banner').length || UserData.data?.email_confirmed_status === 0

      if (hasEmailConfirmationBanner ) {
        setTopOffset(true)
      }
    }, [ UserData.data?.email_confirmed_status])


    return topOffset;
}
