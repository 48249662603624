import 'proxy-polyfill'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import * as Sentry from '@sentry/react'

import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client';

import './i18n'

import NotificationBanner from './Features/NotificationBanner/NotificationBanner.lazy'
import BulkSending from './Features/BulkSending/BulkSending.lazy'
import BusinessSettings from './Features/BusinessSettings/BusinessSettings.router.lazy'
import BulkJobList from './Features/BulkJobList/BulkJobList.lazy'
import BulkJobDetail from './Features/BulkJobDetail/BulkJobDetail.lazy'
import Changelog from './Features/Changelog/Changelog.lazy'
import Signer2fa from './Features/Signer2fa/Signer2fa.lazy'
import Dashboard from './Features/Dashboard/Dashboard.lazy'
import DocumentSigner from './Features/DocumentSigner/DocumentSigner.lazy'
import ContactsNew from './Features/Contacts/new/ContactsNew.lazy'
import ContactsEdit from './Features/Contacts/edit/ContactEdit'
import ContactsList from './Features/Contacts/list/ContactsList.lazy'
import ContactDetails from './Features/Contacts/details/ContactDetails.lazy'
import DocumentsList from './Features/Documents/list/DocumentsList.lazy'
import TemplateList from './Features/Templates/list/TemplateList.lazy'
import ApiDashboardGaugeGraph from './Features/ApiDashboard/GaugeGraph.lazy'
import TrashList from './Features/TrashList/list/TrashList.lazy'
import DeviceVerification from './Features/DeviceVerfication/DeviceVerification.lazy'
import Developer from './Features/Developer/Developer.lazy'
import TeamList from './Features/Team/list/TeamList.lazy'
import DocumentDetail from './Features/Documents/detail/DocumentDetail.lazy'
import StripePayment  from './Features/Subscriptions/StripePayment/StripePayment.lazy'
import UserSettings from './Features/UserSettings/UserSettings.lazy'
import AccountDetails from './Features/AccountDetails/AccountDetails.lazy'
import SignatureList from './Features/Signatures/SignatureList/SignatureList.lazy'
import DocumentSettings from './Features/Documents/settings/DocumentSettings.lazy'
import SubscriptionPage from './Features/Subscriptions/SubscriptionPage/SubscriptionPage.lazy'

declare global {
  interface Window {
    eversignUploadedDocumentFiles: any
    eversignDraftDocumentUrls: any
  }
  interface HTMLElement {
    value: any
  }
}

// in order to reload react scripts on legacy sidebar ajax navigation event,
// we listen here for event from legacy code
// dispatched when user navigates using sidebar
window.addEventListener('ON-SIDEBAR-NAVIGATION', function () {
  initReact()
})

// fixes issues with lazy loading chunks not found
function fixWebpackOriginWebsocket() {
  if (process.env.NODE_ENV !== 'production') {
    if (
      'document' in window &&
      'currentScript' in window.document &&
      document.currentScript !== null
    ) {
      //@ts-ignore
      if (/react.eversign.localhost/.test(document.currentScript.src)) {
        //@ts-ignore
        const url = new URL(document.currentScript.src)
        console.warn(
          //@ts-ignore
          `Path Webpack Public path from ${__webpack_public_path__} to ${
            //@ts-ignore
            url.origin + __webpack_public_path__
          } `,
        )
        //@ts-ignore
        __webpack_public_path__ = url.origin + __webpack_public_path__
        console.warn('this path is necessary for dynamic imports  ')
      }
    }
  }
}

function ErrorFallback({ error, componentStack, resetErrorBoundary }: any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

function mountReactFeatures() {
  const whitelistedFeature = {
    NotificationBanner: NotificationBanner,
    BulkSending: BulkSending,
    BulkJobList: BulkJobList,
    BulkJobDetail: BulkJobDetail,
    Signer2fa: Signer2fa,
    BusinessSettings: BusinessSettings,
    Changelog: Changelog,
    ContactsNew: ContactsNew,
    ContactsList: ContactsList,
    ContactsEdit: ContactsEdit,
    ContactDetails: ContactDetails,
    Dashboard: Dashboard,
    DocumentSigner: DocumentSigner,
    TemplateList: TemplateList,
    DocumentsList: DocumentsList,
    ApiDashboardGaugeGraph: ApiDashboardGaugeGraph,
    TrashList: TrashList,
    DeviceVerification: DeviceVerification,
    Developer: Developer,
    TeamList: TeamList,
    DocumentDetail: DocumentDetail,
    StripePayment: StripePayment,
    UserSettings:UserSettings,
    AccountDetails: AccountDetails,
    SignatureList: SignatureList,
    DocumentSettings: DocumentSettings,
    SubscriptionPage: SubscriptionPage
  }

  for (const key in whitelistedFeature) {
    const node = document.getElementById(key)

    
    if (node && node !== null) {
      const root = createRoot(node); 

      //@ts-ignore
      const Feature = whitelistedFeature[key]
      root.render(
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
          <React.StrictMode>
            <Suspense fallback={null}>
              <Feature />
            </Suspense>
          </React.StrictMode>
        </Sentry.ErrorBoundary>
      )
    } else {
      const element = <h1>Cannot render the feature</h1>
      const container = document.getElementById('root')
      const root = container && createRoot(container); 

      container && root && root.render(element)
    }
  }
}

function initReact() {
  fixWebpackOriginWebsocket()
  mountReactFeatures()
}

initReact()
