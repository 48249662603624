import { format } from 'date-fns'
import { SelectOption } from '../ExpirationAndReminders/ExpirationAndReminders.types'

export const DEFAULT_FONT = [
  { value: 'arial', label: 'Arial' },
  { value: 'calibri', label: 'Calibri' },
  { value: 'courier_new', label: 'Courier New' },
  { value: 'georgia', label: 'Georgia' },
  { value: 'times_new_roman', label: 'Times New Roman' },
]

export type EversignDateFormat = 'YMd' | 'dMY' | 'MdY' | 'Y-m-d' | 'd-m-Y' | 'm-d-Y' | 'd.m.Y' | "Fj,Y" | "jFY"

const eversignDateFormat = [
  'YMd',
  'dMY',
  'MdY',
  'Y-m-d',
  'd-m-Y',
  'm-d-Y',
  'd.m.Y',
  "Fj,Y" , 
  "jFY"
] as EversignDateFormat[]

export function getDateFmsFormatString(dateFormat: EversignDateFormat) {
  switch (dateFormat) {
    case 'YMd':
      return 'yyyy MMM dd'

    case 'dMY':
      return 'dd MMM yyyy'

    case 'MdY':
      return 'MMM dd yyyy'

    case 'Y-m-d':
      return 'yyyy-MM-dd'

    case 'd-m-Y':
      return 'dd-MM-yyyy'

    case 'm-d-Y':
      return 'MM-dd-yyyy'

    case 'd.m.Y':
      return 'dd.MM.yyyy'

    case 'Fj,Y':
      return 'MMMM dd, yyyy'

    case 'jFY':
      return 'dd MMMM yyyy'

    default:
      return 'yyyy-MM-dd'
  }
}





export const createDateFormatSelection = (now: Date = new Date()) =>
  eversignDateFormat.map(
    (value: EversignDateFormat): SelectOption => ({
      label: format(now, getDateFmsFormatString(value)),
      value: value,
    }),
  )

export const TIME_FORMAT = [
  { value: '12', label: '12 Hour' },
  { value: '24', label: '24 Hour' },
]

//@ts-ignore
export const TIMEZONE_LIST = window.timezone as SelectOption[]

export const DEFAULT_LANGUAGE = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'da',
    label: 'Danish',
  },
  {
    value: 'nl',
    label: 'Dutch',
  },
  {
    value: 'fr',
    label: 'French',
  },
  {
    value: 'de',
    label: 'German',
  },
  {
    value: 'hi',
    label: 'Hindi',
  },
  {
    value: 'it',
    label: 'Italian',
  },
  {
    value: 'pl',
    label: 'Polish',
  },
  {
    value: 'pt',
    label: 'Portuguese',
  },
  {
    value: 'ru',
    label: 'Russian',
  },
  {
    value: 'es',
    label: 'Spanish',
  },
  {
    value: 'se',
    label: 'Swedish',
  },
  {
    value: 'tr',
    label: 'Turkish',
  },
]

export const DOCUMENT_LIST_TAB = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'drafts',
    label: 'Drafts',
  },
  {
    value: 'completed',
    label: 'Completed',
  },
  {
    value: 'waiting_for_others',
    label: 'In Process',
  },
  {
    value: 'awaiting_my_signature',
    label: 'I need to sign',
  },
  {
    value: 'cancelled',
    label: 'Cancelled',
  },
]
export const DOCUMENT_LIST_DEFAULT_TAB = DOCUMENT_LIST_TAB[4]

export const SIGNER_GROUP = [
  {
    value: 'just_others',
    label: 'Others Only',
  },
  {
    value: 'me_others',
    label: 'Me & Others',
  },
  {
    value: 'just_me',
    label: 'Me Only',
  },
]
export const DEFAULT_SIGNER_GROUP = SIGNER_GROUP[0]
